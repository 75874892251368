<template>
  <section class="hero is-dark is-fullheight is-bold">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-4 is-offset-4">
            <img
              v-if="$is_liverez"
              src="~@/assets/img/liverez-logo-light.svg"
              alt="Liverez Logo"
              class="logo logo-lg"
            >
            <img
              v-else
              src="~@/assets/img/logo-light.svg"
              alt="LMPM Logo"
              class="logo logo-lg"
            >
            <h1 class="title">
              Reset Your Password
            </h1>
            <h2 class="subtitle">
              Enter your email address so we can email you a password reset code.
            </h2>
            <b-message
              v-if="errorMessage"
              type="is-danger"
            >
              {{ errorMessage }}
            </b-message>

            <form
              accept-charset="UTF-8"
              role="form"
              @submit.stop.prevent="forgotPassword"
            >
              <b-field
                :type="{ 'is-danger': $v.email.$error }"
                :message="{ 'Must be a valid email address': !$v.email.email }"
              >
                <b-input
                  v-model="email"
                  icon="email"
                  placeholder="Email"
                  type="email"
                  autocomplete="email"
                  @input="$v.email.$touch()"
                />
              </b-field>

              <b-field>
                <p class="control">
                  <button
                    :class="{ 'is-loading': loading }"
                    :disabled="$v.$invalid || loading"
                    class="button is-info is-pulled-right"
                    type="submit"
                  >
                    Send Password Reset Code
                  </button>
                </p>
              </b-field>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Raven from 'raven-js'
import { validationMixin } from 'vuelidate'
import {
  required, email
} from 'vuelidate/lib/validators'
import Auth from '@/services/auth'
import { getErrorMessage } from '@/services/helpers'
import { expectedLoginForgotErrorCodes } from '@/store/constants'

export default {
  name: 'LoginForgot',

  mixins: [validationMixin],

  props: {
    prepopEmail: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      email: this.prepopEmail || '',
      errorMessage: null,
      loading: false
    }
  },

  validations: {
    email: {
      required,
      email
    }
  },

  methods: {
    async forgotPassword () {
      this.errorMessage = null
      this.loading = true
      try {
        await Auth.resetPassword({ email: this.email })
        const encodedEmail = encodeURIComponent(this.email)
        this.$router.push(`/login/reset-password?email=${encodedEmail}`)
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!expectedLoginForgotErrorCodes.some(code => code === e.code)) {
          Raven.captureException(e)
        }
        this.errorMessage = getErrorMessage(e)
      }
      this.loading = false
    }
  }
}
</script>
