import Vue from 'vue'
import * as types from '../mutation-types'
import ApiGateway from '@/services/api-gateway'

// Initial state
const state = {}

// Getters
const getters = {
  linkedOwnerList (state) {
    return Object.keys(state).map((companyId) => {
      return state[companyId]
    })
  },
  activeLinkedOwner (state, getters, rootState, rootGetters) {
    return state[rootGetters.activeCompanyId]
  },
  activeLinkedOwnerId (state, getters) {
    if (!getters.activeLinkedOwner) return
    return getters.activeLinkedOwner.id
  }
}

// Actions
const actions = {
  async getLinkedOwners ({ commit }) {
    try {
      const response = await ApiGateway.invokeApi({
        method: 'GET',
        pathTemplate: '/linked-owners'
      })
      const linkedOwners = response.data
      commit(types.RECEIVE_LINKED_OWNERS, { linkedOwners })
    } catch (error) {
      console.error('Error fetching linked owners:', error)
    }
  },
  async linkOwner ({
    commit,
    dispatch
  }, {
    companyId,
    lmpmUserId
  }) {
    if (!(companyId && lmpmUserId)) return
    await ApiGateway.invokeApi({
      method: 'PATCH',
      pathTemplate: '/link-owner',
      body: {
        company_id: companyId,
        user_id: lmpmUserId
      }
    })
    dispatch('changeActiveCompany', { companyId })
    dispatch('getCompany', {
      companyId,
      userId: lmpmUserId
    })
  }
}

// Mutations
const mutations = {
  [types.RECEIVE_LINKED_OWNERS] (state, { linkedOwners }) {
    for (const linkedOwner of linkedOwners) {
      Vue.set(state, linkedOwner.company_id, linkedOwner)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
