import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import { getEnv } from '../helpers/get-env'
import * as getters from './getters'
import auth from './modules/auth'
import bills from './modules/bills'
import companies from './modules/companies'
import linkedOwners from './modules/linked-owners'
import properties from './modules/properties'
import propertyAvailability from './modules/property-availability'
import propertyItems from './modules/property-items'
import statements from './modules/statements'
import * as types from './mutation-types'

Vue.use(Vuex)

const devEnv = getEnv() === 'development'

const store = new Vuex.Store({
  state: { loggingOut: false },
  mutations: {
    [types.SET_LOGGING_OUT] (state, loggingOut) {
      Vue.set(state, 'loggingOut', Boolean(loggingOut))
    }
  },
  getters,
  modules: {
    auth,
    linkedOwners,
    companies,
    properties,
    propertyAvailability,
    propertyItems,
    bills,
    statements
  },
  strict: devEnv,
  plugins: devEnv ? [createLogger()] : []
})

export default store
