import { Amplify } from 'aws-amplify'
import Config from './config'

export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        // REQUIRED - Amazon Cognito Region
        region: process.env.VUE_APP_AWS_REGION || 'us-east-1',

        // REQUIRED - Amazon Cognito User Pool ID
        userPoolId: Config.UserPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: Config.ClientId,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        cookieStorage: {
          // REQUIRED - Cookie domain (only required if cookieStorage is provided)
          domain: window.location.hostname,
          // OPTIONAL - Cookie path
          path: '/',
          // OPTIONAL - Cookie expiration in days
          expires: 1,
          // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
          sameSite: 'strict',
          // OPTIONAL - Cookie secure flag
          // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
          secure: window.location.protocol === 'https:'
        },

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',

        loginWith: { username: true }
      }
    }
  })
}

export default configureAmplify
