import qs from 'querystringify'
import {
  prodImageHost, stagingImageHost, devImageHost, prodS3CompanyBucket, devS3CompanyBucket
} from '../constants'
import { getEnv } from './get-env'

const prodEnvs = [
  'prod',
  'production',
  'prod-qa',
  'staging'
]

/**
 * Generate an image URL based on the passed parameters, using intelligent defaults based on the current environment.
 * @param {Object} params - Function parameters.
 * @param {string} params.companyId - ID of the company that owns the image.
 * @param {string} params.path - File path within the S3 bucket.
 * @param {string} params.fileName - Name of the file including extension.
 * @param {Object} [params.queryParams] - Object of query params that will be appended to the URL as a query string.
 * @returns {string} The generated image URL
 */
export function imgUrl ({
  companyId,
  path,
  fileName,
  queryParams
}) {
  const env = getEnv()
  const bucket = prodEnvs.includes(env) ? prodS3CompanyBucket : devS3CompanyBucket
  const host = env === 'production' ? prodImageHost : env === 'staging' ? stagingImageHost : devImageHost
  const queryString = queryParams != null ? qs.stringify(queryParams, true) : ''
  return `${host}/img/${bucket}/${companyId}/${path}/${fileName}${queryString}`
}
