<template>
  <section class="property-bill-list">
    <form class="level">
      <div class="level-left">
        <div class="level-item">
          <month-year-picker
            v-model="date"
            :max-years-forward="5"
            :max-years-back="10"
            :disabled="loading"
          />
        </div>
      </div>
    </form>
    <loading-spinner :loading="loading" />
    <b-message
      v-if="errorMessage"
      type="is-danger"
    >
      {{ errorMessage }}
    </b-message>
    <div class="list-items">
      <bill-list-item
        v-for="bill of bills"
        :id="bill.id"
        :key="bill.id"
        :property-id="bill.property_id"
        :vendor="bill.vendor"
        :date="bill.date"
        :currency="bill.currency"
        :total-amount="bill.total_amount"
        :reference="bill.reference"
      />
    </div>
    <no-results v-if="!loading && !bills.length && !errorMessage">
      No charges to display.
    </no-results>
  </section>
</template>

<script>
import endOfMonth from 'date-fns/end_of_month'
import format from 'date-fns/format'
import Raven from 'raven-js'
import LoadingSpinner from 'vue-spinner/src/MoonLoader'
import BillListItem from '@/components/BillListItem.vue'
import MonthYearPicker from '@/components/MonthYearPicker.vue'
import NoResults from '@/components/NoResults.vue'
import { getErrorMessage } from '@/services/helpers'

export default {
  name: 'BillList',

  components: {
    LoadingSpinner,
    BillListItem,
    NoResults,
    MonthYearPicker
  },

  props: {
    propertyId: {
      type: String,
      default: ''
    },
    month: {
      type: Number,
      default: 0
    },
    year: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      bills: [],
      errorMessage: null,
      loading: true,
      date: {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        month: this.month || new Date().getMonth(),
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        year: this.year || new Date().getFullYear()
      }
    }
  },

  computed: {
    startDate () { return new Date(this.date.year, this.date.month, 1) },
    start () { return format(this.startDate, 'YYYY-MM-DD') },
    end () { return format(endOfMonth(this.startDate), 'YYYY-MM-DD') }
  },

  watch: {
    async date () {
      const month = this.date.month + 1
      const year = this.date.year
      this.$router.replace({
        query: {
          month,
          year
        }
      })
      await this.getBills()
    }
  },

  async created () { await this.getBills() },

  methods: {
    async getBills () {
      this.errorMessage = null
      this.loading = true
      this.bills = []
      try {
        const propertyId = this.propertyId
        const start = this.start
        const end = this.end
        await this.$store.dispatch('getPropertyBills', {
          propertyId,
          start,
          end
        })
        this.bills = this.$store.getters.billsForProperty({ propertyId })
      } catch (e) {
        Raven.captureException(e)
        this.errorMessage = getErrorMessage(e)
      }
      this.loading = false
    }
  }
}
</script>
