<template>
  <section class="section property-list">
    <div class="container">
      <loading-spinner
        :loading="loading"
        class="is-fullpage"
      />
      <b-message
        v-if="errorMessage"
        type="is-danger"
      >
        {{ errorMessage }}
      </b-message>
      <div
        v-if="!loading"
        class="list-items"
      >
        <property-list-item
          v-for="property of properties"
          :id="property.id"
          :key="property.id"
          :name="property.friendly_name"
          :images="property.images"
          :bathrooms="property.bathrooms"
          :bedrooms="property.bedrooms"
          :sq-feet="property.sq_feet"
          :star-rating="property.star_rating"
          :occupancy="property.occupancy"
          :max-occupancy="property.max_occupancy"
        />
      </div>
      <no-results v-if="!loading && !properties.length && !errorMessage">
        You don't own any properties yet.
      </no-results>
    </div>
  </section>
</template>

<script>
import Raven from 'raven-js'
import LoadingSpinner from 'vue-spinner/src/MoonLoader'
import { mapGetters } from 'vuex'
import NoResults from '@/components/NoResults.vue'
import PropertyListItem from '@/components/PropertyListItem.vue'
import { getErrorMessage } from '@/services/helpers'

export default {
  name: 'PropertyList',

  components: {
    LoadingSpinner,
    PropertyListItem,
    NoResults
  },

  data () {
    return {
      errorMessage: null,
      loading: true
    }
  },

  computed: mapGetters({ properties: 'allProperties' }),

  async created () {
    try {
      await this.$store.dispatch('getAllProperties')
      if (this.properties.length === 1) {
        // If there's only 1 property, just show it.
        this.$router.push(`/properties/${this.properties[0].id}/future-bookings`)
      } else {
        this.loading = false
      }
    } catch (e) {
      Raven.captureException(e)
      this.errorMessage = getErrorMessage(e)
      this.loading = false
    }
  }
}
</script>
