import {
  signUp,
  signIn,
  signOut,
  getCurrentUser,
  fetchAuthSession,
  fetchUserAttributes,
  resetPassword,
  confirmResetPassword
} from 'aws-amplify/auth'
import Raven from 'raven-js'
import ApiGateway from '@/services/api-gateway'
import Config from '@/services/config'
import store from '@/store'
import { expectedSecureRouteErrorCodes } from '@/store/constants'

export default class Auth {
  /**
   * Wrapper for signUp method.
   */
  static async signUp ({
    username,
    password,
    options
  }) {
    try {
      return signUp({
        username,
        password,
        options
      })
    } catch (error) {
      Raven.captureException(error)
      throw error
    }
  }

  /**
   * Login with Cognito User Pool.
   * @param {Object} params - Method params.
   * @param {String} params.email - Email address.
   * @param {String} params.password - Password.
   */
  static async login ({
    email,
    password
  }) {
    try {
      Raven.captureBreadcrumb({
        message: 'Logging in with email/password',
        category: 'action',
        data: { email }
      })
      Raven.setUserContext({ email })

      return signIn({
        username: email,
        password
      })
    } catch (error) {
      Raven.captureException(error)
      throw error
    }
  }

  /**
   * Get the current authenticated user
   */
  static async getCurrentUser () {
    try {
      return await getCurrentUser()
    } catch (error) {
      Raven.captureException(error)
      throw error
    }
  }

  /**
   * Get the current session including tokens
   */
  static async getCurrentSession () {
    try {
      const session = await fetchAuthSession()
      return session
    } catch (error) {
      Raven.captureException(error)
      throw error
    }
  }

  /**
   * Authenticate with Cognito Identity Pool using passed login tokens.
   * Initialize Cognito Sync datasets and populate them with data.
   * Initialize API Gateway client.
   */
  static async initSession (companyId, lmpmUserId) {
    try {
      // Step 1: Ensure user is logged in
      if (!await Auth.isLoggedIn()) {
        Raven.captureBreadcrumb({
          message: 'User isn\'t logged in - attempting to reuse existing session',
          category: 'info'
        })

        const user = await Auth.getCurrentUser()
        if (!user) {
          throw new Error('No authenticated user found')
        }
      }

      // Step 2: Load user attributes if needed
      if (!await Auth.userAttributesLoaded()) {
        Raven.captureBreadcrumb({
          message: 'User attributes haven\'t been loaded yet - initializing new session',
          category: 'info'
        })

        const user = await Auth.getCurrentUser()
        const session = await Auth.getCurrentSession()

        if (!user || !session) {
          throw new Error('No authenticated user found')
        }

        // Get user attributes and update store
        const userAttributes = await fetchUserAttributes()
        await store.dispatch('auth/setUser', {
          user,
          attributes: userAttributes,
          tokens: {
            IdToken: session.tokens?.idToken?.toString(),
            AccessToken: session.tokens?.accessToken?.toString(),
            RefreshToken: session.tokens?.refreshToken?.toString()
          }
        })

        // Set Raven user context for error tracking
        Raven.setUserContext({
          id: user.userId,
          firstName: userAttributes.given_name,
          lastName: userAttributes.family_name,
          email: userAttributes.email
        })

        // Initialize API Gateway
        await ApiGateway.init({
          invokeUrl: Config.ApiGwUrl,
          headers: { Authorization: session.tokens?.idToken?.toString() }
        })
      }

      // Step 3: Link owner if company ID and user ID are provided
      if (companyId && lmpmUserId) {
        await store.dispatch('linkOwner', {
          companyId,
          lmpmUserId
        })

        // Set as active company if user exists in store
        if (store.getters['auth/user']) {
          await store.dispatch('auth/updateUserAttributes', { 'custom:active_company_id': companyId })
          await store.dispatch('auth/getUserAttributes')
        }
      }

      // Step 4: Always fetch linked owners and companies to provide all linked companies for the active user
      await store.dispatch('getLinkedOwners')
      await store.dispatch('getAllCompanies')
    } catch (error) {
      // Handle authentication errors
      if (error.code === 'NotAuthorizedException' || error.code === 'ResourceNotFoundException') {
        await Auth.logout()
      }
      Raven.captureException(error)
      throw error
    }
  }

  /**
   * Is the user logged in?
   */
  static async isLoggedIn () {
    try {
      const session = await Auth.getCurrentSession()
      const isLoggedIn = !!session && !!session.tokens
      return isLoggedIn
    } catch (error) {
      Raven.captureException(error)
      throw error
    }
  }

  /**
   * Have the user's attributes been loaded from Cognito yet?
   */
  static async userAttributesLoaded () {
    try {
      // First check if the user exists in the store
      const userInStore = store.getters['auth/user']

      if (!userInStore) {
        return false
      }
      // Then check if we can fetch user attributes from Cognito
      const userAttributes = await fetchUserAttributes()
      const hasEmail = !!userAttributes && 'email' in userAttributes
      return hasEmail
    } catch (error) {
      Raven.captureException(error)
      throw error
    }
  }

  /**
   * Log out all logins and empty local storage.
   */
  static async logout () {
    try {
      Raven.captureBreadcrumb({
        message: 'Logging out',
        category: 'action'
      })
      if (await Auth.isLoggedIn()) {
        store.commit('SET_LOGGING_OUT', true)
        await signOut()
        await store.dispatch('auth/clearUser')
      }
      Raven.setUserContext()
    } catch (error) {
      Raven.captureException(error)
      throw error
    }
  }

  /**
   * Wrapper for `resetPassword` method.
   */
  static async resetPassword ({ email }) {
    try {
      const { username } = await ApiGateway.userExists({ email })
      if (!username) {
        throw new Error('No user found')
      }
      return resetPassword({ username })
    } catch (error) {
      console.error('Error in resetPassword:', error)
      throw error
    }
  }

  /**
   * Wrapper for `confirmResetPassword` method.
   */
  static async confirmResetPassword ({
    username,
    confirmationCode,
    newPassword
  }) {
    return confirmResetPassword({
      username,
      confirmationCode,
      newPassword
    })
  }

  // vue-router navigation guards //

  /**
   * Make sure the user is logged in before navigating to a route.
   */
  static async secureRoute (routeTo, routeFrom, next) {
    try {
      Raven.captureBreadcrumb({
        message: 'Securing route',
        category: 'action',
        data: {
          routeTo: Object.assign({}, routeTo, { matched: '** redacted due to Sentry request size limit **' }),
          routeFrom: Object.assign({}, routeFrom, { matched: '** redacted due to Sentry request size limit **' })
        }
      })
      await Auth.initSession()
    } catch (e) {
      let error
      if (!(e instanceof Error) && e.message) {
        error = new Error(e.message)
        error.code = e.code
      } else {
        error = e
      }

      if (!expectedSecureRouteErrorCodes.some(code => code === error.code)) {
        Raven.captureException(error)
      }

      return next({
        path: '/login',
        query: {
          redirect: routeTo.fullPath,
          expired: true
        }
      })
    }

    next()
  }

  /**
   * Clear cached auth before navigating to the target route.
   * Useful for login/signup pages in case the user wants to use different credentials.
   */
  static async clearCachedAuth (to, from, next) {
    if (await Auth.isLoggedIn()) await Auth.logout()
    next()
  }

  /**
   * Log out the user by navigating to a route.
   */
  static async routerLogout (to, from, next) {
    await Auth.logout()
    Auth.logoutBrowserRedirect()
  }

  /**
   * Set `window.location` in a wrapper method so it can be stubbed in tests.
   */
  static logoutBrowserRedirect () {
    window.location = '/login?signedOut=true'
  }
}
