<template>
  <section class="hero is-dark is-fullheight is-bold">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-4 is-offset-4">
            <img
              v-if="$is_liverez"
              src="~@/assets/img/liverez-logo-light.svg"
              alt="Liverez Logo"
              class="logo logo-lg"
            >
            <img
              v-else
              src="~@/assets/img/logo-light.svg"
              alt="LMPM Logo"
              class="logo logo-lg"
            >
            <b-message
              v-if="!linkValid"
              type="is-danger"
            >
              Invalid sign up link. Please contact your LMPM admin.
            </b-message>

            <div v-if="linkValid">
              <h1 class="title">
                Owner Sign Up
              </h1>
              <b-message
                v-if="errorMessage"
                type="is-danger"
              >
                {{ errorMessage }}
              </b-message>

              <section class="sign-up-form">
                <b-message
                  v-if="successMessage"
                  type="is-success"
                >
                  {{ successMessage }}
                </b-message>
                <b-message
                  v-if="userExists"
                  type="is-danger"
                >
                  <span>A user already exists with this email address.</span>
                  <br><br>
                  <p class="has-text-centered">
                    <button
                      class="button is-info"
                      type="button"
                      @click="existingUserLogin"
                    >
                      Login With Existing Account
                    </button>
                  </p>
                </b-message>

                <transition name="reveal">
                  <form
                    class="email-signup"
                    role="form"
                    @submit.stop.prevent="userPoolSignUp"
                  >
                    <b-field :type="{ 'is-danger': $v.firstName.$error }">
                      <b-input
                        v-model="firstName"
                        icon="account-circle"
                        placeholder="First Name"
                        autocomplete="given-name"
                        @input="$v.firstName.$touch()"
                      />
                    </b-field>

                    <b-field :type="{ 'is-danger': $v.lastName.$error }">
                      <b-input
                        v-model="lastName"
                        icon="account-circle"
                        placeholder="Last Name"
                        autocomplete="family-name"
                        @input="$v.lastName.$touch()"
                      />
                    </b-field>

                    <b-field
                      :type="{ 'is-danger': $v.email.$error }"
                      :message="{ 'Must be a valid email address': !$v.email.email }"
                    >
                      <b-input
                        v-model="email"
                        icon="email"
                        placeholder="Email"
                        autocomplete="email"
                        @input="$v.email.$touch()"
                      />
                    </b-field>

                    <b-field
                      :type="{ 'is-danger': $v.password.$error }"
                      :message="[
                        { 'Password must be at least 8 characters': !$v.password.minLength },
                        { 'Password must contain a number': !$v.password.containsNumber },
                        { 'Password must contain a lowercase letter': !$v.password.containsLowercase },
                        { 'Password must contain an uppercase letter': !$v.password.containsUppercase }
                      ]"
                    >
                      <b-input
                        v-model="password"
                        icon="lock"
                        placeholder="Password"
                        type="password"
                        autocomplete="new-password"
                        @input="$v.password.$touch()"
                      />
                    </b-field>

                    <b-field
                      :type="{ 'is-danger': $v.passwordConfirmation.$error }"
                      :message="[
                        { 'Password must be at least 8 characters': !$v.passwordConfirmation.minLength },
                        { 'Password confirmation must match': !$v.passwordConfirmation.sameAs }
                      ]"
                    >
                      <b-input
                        v-model="passwordConfirmation"
                        icon="lock"
                        placeholder="Confirm Password"
                        type="password"
                        autocomplete="new-password"
                        @input="$v.passwordConfirmation.$touch()"
                      />
                    </b-field>

                    <b-field>
                      <p class="control">
                        <button
                          :class="{ 'is-loading': loading }"
                          :disabled="$v.$invalid || loading"
                          class="button is-success is-medium is-fullwidth"
                          type="submit"
                        >
                          Submit
                        </button>
                      </p>
                    </b-field>
                  </form>
                </transition>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Raven from 'raven-js'
import uuid from 'uuid/v4'
import { validationMixin } from 'vuelidate'
import {
  required, minLength, email, sameAs
} from 'vuelidate/lib/validators'
import ApiGateway from '@/services/api-gateway'
import Auth from '@/services/auth'
import { getErrorMessage } from '@/services/helpers'
import containsLowercase from '@/validators/contains-lowercase'
import containsNumber from '@/validators/contains-number'
import containsUppercase from '@/validators/contains-uppercase'

export default {
  name: 'SignUp',

  mixins: [validationMixin],

  props: {
    companyId: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      default: ''
    },
    prepopEmail: {
      type: String,
      default: ''
    },
    prepopFirstName: {
      type: String,
      default: ''
    },
    prepopLastName: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      username: uuid(),
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      email: this.prepopEmail || '',
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      firstName: this.prepopFirstName || '',
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      lastName: this.prepopLastName || '',
      profilePicture: '',
      password: '',
      passwordConfirmation: '',
      loading: false,
      errorMessage: null,
      successMessage: null,
      userExists: false,
      logins: null
    }
  },

  validations: {
    email: {
      required,
      email
    },
    firstName: { required },
    lastName: { required },
    password: {
      required,
      minLength: minLength(8),
      containsLowercase,
      containsUppercase,
      containsNumber
    },
    passwordConfirmation: {
      required,
      minLength: minLength(8),
      sameAs: sameAs('password')
    }
  },

  computed: {
    linkValid () {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return Boolean(this.companyId && this.userId)
    },
    loginLink () {
      return `/login?email=${this.email}&companyId=${this.companyId}&userId=${this.userId}`
    }
  },

  methods: {
    async userPoolSignUp () {
      this.loading = true
      this.successMessage = null
      this.errorMessage = null
      this.email = this.email.toLowerCase()
      try {
        this.userExists = (await ApiGateway.userExists({ email: this.email })).userExists
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!this.userExists) await this.signUp()
      } catch (e) {
        if (e.code === 'UsernameExistsException') {
          this.userExists = true
        } else {
          Raven.captureException(e)
          this.errorMessage = getErrorMessage(e)
        }
      }
      this.loading = false
    },

    async signUp () {
      try {
        // Create user in Cognito user pool using Amplify Auth directly
        await Auth.signUp({
          username: this.username,
          password: this.password,
          options: {
            userAttributes: {
              email: this.email,
              given_name: this.firstName,
              family_name: this.lastName
            }
          }
        })

        await Auth.login({
          email: this.email,
          password: this.password
        })

        await Auth.initSession(this.companyId, this.userId)
        this.$router.push('/properties')
      } catch (error) {
        Raven.captureException(error)
        this.errorMessage = getErrorMessage(error)
      }
    },

    async existingUserLogin () {
      await Auth.logout()
      window.location = this.loginLink
    }
  }
}
</script>

<style lang="sass" scoped>
.logo,
.title
  position: relative
  z-index: 2

.email-signup
  z-index: 1
</style>
