<template>
  <section class="hero is-dark is-fullheight is-bold">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-4 is-offset-4">
            <img
              v-if="$is_liverez"
              src="~@/assets/img/liverez-logo-light.svg"
              alt="Liverez Logo"
              class="logo logo-lg"
            >
            <img
              v-else
              src="~@/assets/img/logo-light.svg"
              alt="LMPM Logo"
              class="logo logo-lg"
            >
            <h1 class="title">
              Reset Your Password
            </h1>
            <h2 class="subtitle">
              Choose a new password and enter the verification code we emailed you.
            </h2>
            <b-message
              v-if="errorMessage"
              type="is-danger"
            >
              {{ errorMessage }}
            </b-message>

            <form
              accept-charset="UTF-8"
              role="form"
              @submit.stop.prevent="resetPassword"
            >
              <b-field>
                <b-input
                  :value="email"
                  icon="email"
                  type="email"
                  autocomplete="email"
                  disabled
                />
              </b-field>

              <b-field
                :type="{ 'is-danger': $v.password.$error }"
                :message="[
                  { 'Password must be at least 8 characters': !$v.password.minLength },
                  { 'Password must contain a number': !$v.password.containsNumber },
                  { 'Password must contain an uppercase letter': !$v.password.containsUppercase }
                ]"
              >
                <b-input
                  v-model="password"
                  icon="lock"
                  type="password"
                  placeholder="New Password"
                  autocomplete="new-password"
                  @input="$v.password.$touch()"
                />
              </b-field>

              <b-field
                :type="{ 'is-danger': $v.code.$error }"
                :message="{ 'Verification code must be 6 characters': !$v.code.minLength || !$v.code.maxLength }"
              >
                <b-input
                  v-model="code"
                  icon="dialpad"
                  type="tel"
                  placeholder="Verification Code"
                  autocomplete="code"
                  @input="$v.code.$touch()"
                />
              </b-field>

              <b-field>
                <p class="control">
                  <button
                    :disabled="$v.$invalid || loading"
                    :class="{ 'is-loading': loading }"
                    class="button is-info is-pulled-right"
                    type="submit"
                  >
                    Reset Password
                  </button>
                </p>
              </b-field>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Raven from 'raven-js'
import { validationMixin } from 'vuelidate'
import {
  required, minLength, maxLength
} from 'vuelidate/lib/validators'
import Auth from '@/services/auth'
import { getErrorMessage } from '@/services/helpers'
import containsNumber from '@/validators/contains-number'
import containsUppercase from '@/validators/contains-uppercase'

export default {
  name: 'LoginResetPassword',

  mixins: [validationMixin],

  props: {
    email: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      code: '',
      password: '',
      errorMessage: null,
      loading: false
    }
  },

  validations: {
    code: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6)
    },
    password: {
      required,
      minLength: minLength(8),
      containsUppercase,
      containsNumber
    }
  },

  methods: {
    async resetPassword () {
      this.errorMessage = null
      this.loading = true
      try {
        const username = this.email
        const code = this.code
        const newPassword = this.password

        await Auth.confirmResetPassword({
          username,
          confirmationCode: code,
          newPassword
        })

        const encodedEmail = encodeURIComponent(this.email)
        this.$router.push(`/login?email=${encodedEmail}`)
      } catch (e) {
        Raven.captureException(e)
        this.errorMessage = getErrorMessage(e)
      }
      this.password = ''
      this.loading = false
    }
  }
}
</script>
