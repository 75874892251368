<template>
  <section class="section view-property">
    <div class="container">
      <loading-spinner :loading="loading" />
      <b-message
        v-if="errorMessage"
        type="is-danger"
      >
        {{ errorMessage }}
      </b-message>
      <div v-if="bill">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <div>
                <button
                  class="button has-icon"
                  @click="goBack"
                >
                  <b-icon icon="chevron-left" />
                </button>
              </div>
            </div>

            <div class="level-item">
              <div>
                <h1 class="title">
                  <span v-if="bill.reference">{{ bill.reference }}</span>
                  <span v-if="!bill.reference">{{ bill.vendor.name }}</span>
                </h1>
                <h2
                  v-if="bill.reference"
                  class="heading"
                >
                  {{ bill.vendor.name }}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <hr>

        <div class="bill-details level">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                Amount
              </p>
              <p class="title">
                {{ formattedAmount }}
              </p>
            </div>
          </div>

          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                Bill Date
              </p>
              <p class="title">
                {{ formattedDate }}
              </p>
            </div>
          </div>

          <div
            v-if="bill.issue_date"
            class="level-item has-text-centered"
          >
            <div>
              <p class="heading">
                Issued
              </p>
              <p class="title">
                {{ formattedIssueDate }}
              </p>
            </div>
          </div>

          <div
            v-if="bill.received_date"
            class="level-item has-text-centered"
          >
            <div>
              <p class="heading">
                Received
              </p>
              <p class="title">
                {{ formattedReceivedDate }}
              </p>
            </div>
          </div>

          <div
            v-if="bill.due_date"
            class="level-item has-text-centered"
          >
            <div>
              <p class="heading">
                Due
              </p>
              <p class="title">
                {{ formattedDueDate }}
              </p>
            </div>
          </div>

          <div
            v-if="bill.vendor.contact_email"
            class="level-item has-text-centered"
          >
            <div>
              <p class="heading">
                Contact
              </p>
              <p class="title">
                <a
                  :href="contactHref"
                  class="button has-icon"
                >
                  <span>{{ bill.vendor.contact_email }}</span>
                </a>
              </p>
            </div>
          </div>
        </div>
        <hr>

        <image-gallery
          v-if="bill.images.length"
          :images="bill.images"
          :view-original="true"
        />
      </div>
    </div>
  </section>
</template>

<script>
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import Raven from 'raven-js'
import LoadingSpinner from 'vue-spinner/src/MoonLoader'
import { mapGetters } from 'vuex'
import ImageGallery from '@/components/ImageGallery.vue'
import { getErrorMessage } from '@/services/helpers'

export default {
  name: 'BillView',

  components: {
    LoadingSpinner,
    ImageGallery
  },

  props: {
    propertyId: {
      type: String,
      default: ''
    },
    billId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      bill: null,
      errorMessage: null,
      loading: true
    }
  },

  computed: {
    formattedDate () { return format(parse(this.bill.date), 'MMM D, YYYY') },
    formattedIssueDate () { return format(parse(this.bill.issue_date), 'MMM D, YYYY') },
    formattedReceivedDate () { return format(parse(this.bill.received_date), 'MMM D, YYYY') },
    formattedDueDate () { return format(parse(this.bill.due_date), 'MMM D, YYYY') },
    formattedAmount () { return this.$store.getters.formattedIntCurrencyAmount(this.bill.total_amount) },
    contactHref () { return `mailto:${this.bill.vendor.contact_email}` },
    backLink () { return `/properties/${this.propertyId}/bills` },
    ...mapGetters(['activeBill'])
  },

  async created () {
    this.loading = true
    this.errorMessage = null
    try {
      const propertyId = this.propertyId
      const billId = this.billId
      await this.$store.dispatch('getActiveBill', {
        propertyId,
        billId
      })
      this.bill = this.activeBill
    } catch (e) {
      Raven.captureException(e)
      this.errorMessage = getErrorMessage(e)
    }
    this.loading = false
  },

  methods: { goBack () { this.$router.go(-1) } }
}
</script>

<style lang="sass" scoped>
.bill-details
  flex-wrap: wrap

  .level-item
    margin: 2rem
    flex-basis: 25%
</style>
