import Vue from 'vue'
import Router from 'vue-router'

// Components
import BillList from '@/components/BillList.vue'
import BillView from '@/components/BillView.vue'
import Login from '@/components/Login.vue'
import LoginForgot from '@/components/LoginForgot.vue'
import LoginResetPassword from '@/components/LoginResetPassword.vue'
import PropertyBook from '@/components/PropertyBook.vue'
import PropertyDetails from '@/components/PropertyDetails.vue'
import PropertyItemList from '@/components/PropertyItemList.vue'
import PropertyList from '@/components/PropertyList.vue'
import PropertyStatistics from '@/components/PropertyStatistics.vue'
import PropertyView from '@/components/PropertyView.vue'
import Settings from '@/components/Settings.vue'
import SignUp from '@/components/SignUp.vue'
import StatementGroups from '@/components/StatementGroups.vue'
import StatementList from '@/components/StatementList.vue'

// Services
import Auth from '@/services/auth'

Vue.use(Router)

export function scrollBehavior (to, from, savedPosition) {
  if (savedPosition) return savedPosition
  const position = {}
  if (to.hash) position.selector = to.hash // scroll to anchor by returning the selector
  // if the returned position is falsy or an empty object, will retain current scroll position.
  return position
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes: [
    {
      path: '/',
      redirect: '/redirect'
    },
    {
      path: '/redirect',
      component: { render: h => h('div') }, // Empty component
      beforeEnter: async (to, from, next) => {
        try {
          const isLoggedIn = await Auth.isLoggedIn()
          const redirectPath = isLoggedIn ? '/properties' : '/login'
          next(redirectPath)
        } catch (error) {
          console.error('Error checking login status:', error)
          next('/login')
        }
      }
    },
    {
      path: '/login',
      component: Login,
      meta: {
        clearAuth: true,
        hideNav: true
      },
      props: (route) => ({
        companyId: route.query.companyId,
        expired: Boolean(route.query.expired),
        prepopEmail: route.query.email ? decodeURIComponent(route.query.email) : '',
        redirect: route.query.redirect,
        signedOut: Boolean(route.query.signedOut),
        userId: route.query.userId
      }),
      beforeEnter: Auth.clearCachedAuth
    },
    {
      path: '/login/forgot',
      component: LoginForgot,
      meta: {
        clearAuth: true,
        hideNav: true
      },
      props: (route) => ({ prepopEmail: route.query.email ? decodeURIComponent(route.query.email) : '' })
    },
    {
      path: '/login/reset-password',
      component: LoginResetPassword,
      meta: {
        clearAuth: true,
        hideNav: true
      },
      props: (route) => ({ email: route.query.email ? decodeURIComponent(route.query.email) : '' })
    },
    {
      path: '/sign-up',
      component: SignUp,
      meta: {
        clearAuth: true,
        hideNav: true
      },
      props: (route) => ({
        companyId: route.query.companyId,
        userId: route.query.userId,
        prepopEmail: route.query.email,
        prepopFirstName: route.query.firstName,
        prepopLastName: route.query.lastName
      })
    },
    {
      path: '/properties',
      component: PropertyList,
      meta: {
        needsAuth: true,
        needsCompany: true
      }
    },
    {
      path: '/properties/:propertyId',
      component: PropertyView,
      meta: {
        needsAuth: true,
        needsCompany: true
      },
      props: (route) => ({ propertyId: route.params.propertyId }),
      children: [
        {
          path: 'statistics',
          component: PropertyStatistics
        },
        {
          path: 'details',
          component: PropertyDetails
        },
        {
          path: 'book',
          component: PropertyBook
        },
        {
          path: 'future-bookings',
          component: PropertyItemList,
          props: (route) => ({
            propertyId: route.params.propertyId,
            future: true
          })
        },
        {
          path: 'past-bookings',
          component: PropertyItemList,
          props: (route) => ({
            propertyId: route.params.propertyId,
            future: false
          })
        },
        {
          path: 'charges',
          component: BillList,
          props: (route) => ({
            propertyId: route.params.propertyId,
            month: Number(route.query.month) - 1,
            year: Number(route.query.year)
          })
        }
      ]
    },
    {
      path: '/properties/:propertyId/charges/:billId',
      component: BillView,
      meta: {
        needsAuth: true,
        needsCompany: true
      },
      props: (route) => ({
        propertyId: route.params.propertyId,
        billId: route.params.billId
      })
    },
    {
      path: '/statements',
      component: StatementGroups,
      meta: {
        needsAuth: true,
        needsCompany: true
      }
    },
    {
      path: '/statements/:userId',
      component: StatementList,
      meta: {
        needsAuth: true,
        needsCompany: true
      },
      props: (route) => ({ userId: route.params.userId })
    },
    {
      path: '/settings',
      component: Settings,
      meta: { needsAuth: true }
    },
    {
      path: '/logout',
      beforeEnter: Auth.routerLogout
    }
  ]
})

// Global navigation guard
router.beforeEach(beforeEachHandler)
export function beforeEachHandler (to, from, next) {
  if (to.matched.some(record => record.meta.needsAuth)) {
    Auth.secureRoute(to, from, next)
  } else if (to.matched.some(record => record.meta.clearAuth)) {
    Auth.clearCachedAuth(to, from, next)
  } else {
    next()
  }
}

export default router
