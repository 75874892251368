import apigClientFactory from 'aws-api-gateway-client'
import AWS from 'aws-sdk/global'
import axios from 'axios'
import { defaultsDeep } from 'lodash'
import { getEnv } from '../helpers/get-env'
import Config from '@/services/config'
import store from '@/store'

export const retries = 3 // How many times to retry failed requests.
export const region = 'us-east-1'

// Wrapper for API Gateway client
export default class ApiGateway {
  /**
   * Make an unauthenticated request to check if a user has already registered
   * with the given email address.
   *
   * @param {Object} _ref.email - Email address to check.
   * @returns {Promise}
   */
  static async userExists ({ email }) {
    const response = await axios.get(`${Config.ApiGwUrl}/cognito/exists/${encodeURIComponent(email)}`)
    return response.data
  }

  /**
   * Initialize and store an instance of the API Gateway client.
   *
   * @param {Object} config - Client config object.
   * @returns {Object} - Config object used when instatiating the client (for testing/debugging).
   */
  static init (config) {
    const defaultConfig = {
      region,
      systemClockOffset: AWS.config.systemClockOffset,
      retryCondition: this.retryCondition,
      retries
    }
    const clientConfig = defaultsDeep({}, defaultConfig, config)
    this.client = apigClientFactory.newClient(clientConfig)
    return clientConfig
  }

  /**
   * Callback to tell client wether or not a failed request should be retried.
   * @param {Error} error - Axios error object from failed request.
   * @returns {Boolean}
   */
  static retryCondition (error) {
    if (!error.response) return false
    return error.response.status >= 500
  }

  /**
   * Proxy method for invoking the API.
   *
   * @param {Object} arguments - A hash of arguments for the proxied method.
   * @returns {Promise}
   */
  static async invokeApi ({
    params,
    pathTemplate,
    method,
    additionalParams,
    body
  }) {
    additionalParams = this._addDevCognitoHeaders(additionalParams)
    try {
      const response = await this.client.invokeApi(params, pathTemplate, method, additionalParams, body)
      return response
    } catch (e) {
      if (e.response && e.response.status === 401) {
        await store.dispatch('getCurrentUser') // Try to refresh the session token.
        const response = await this.client.invokeApi(params, pathTemplate, method, additionalParams, body)
        return response
      }
      throw e
    }
  }

  /**
   * This is a hack to give a locally-running Serverless service access to the
   * developer's Cognito Identity ID via a custom header.
   *
   * Normally when deployed to AWS, the lambda can access this ID via the
   * `event` object. But when running locally with `serverless-offline` the
   * event only contains a generic placeholder value instead of the actual ID.
   *
   * @param {Object} additionalParams
   * @returns {Object}
   */
  static _addDevCognitoHeaders (additionalParams) {
    if (getEnv() !== 'local') return additionalParams
    const headers = { 'Local-Developer-Cognito-Identity-Id': Config.getCurrentUserCognitoUsername() }
    additionalParams = defaultsDeep({ headers }, additionalParams)
    return additionalParams
  }
}
