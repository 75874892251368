import { render, staticRenderFns } from "./ImageGallery.vue?vue&type=template&id=4b091e74&scoped=true"
import script from "./ImageGallery.vue?vue&type=script&lang=js"
export * from "./ImageGallery.vue?vue&type=script&lang=js"
import style0 from "./ImageGallery.vue?vue&type=style&index=0&id=4b091e74&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b091e74",
  null
  
)

export default component.exports