<template>
  <section class="property-statistics">
    <loading-spinner :loading="loading" />
    <div
      v-if="!loading"
      class="columns is-multiline"
    >
      <div class="column is-half">
        <label class="label">Current Rates</label>
        <vue-chartist
          :data="rateData"
          :options="rateOptions"
          type="Line"
        />
      </div>

      <div class="column is-half">
        <label class="label">Booking Revenue</label>
        <vue-chartist
          :data="revenueData"
          :options="revenueOptions"
          type="Bar"
        />
      </div>

      <div class="column is-half">
        <label class="label">Monthly Occupancy</label>
        <vue-chartist
          :data="occupancyData"
          :options="occupancyOptions"
          type="Bar"
        />
      </div>

      <div class="column is-half">
        <label class="label">Reservation Sources</label>
        <vue-chartist
          :data="sourceData"
          :options="sourceOptions"
          type="Pie"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Chartist from 'chartist'
import format from 'date-fns/format'
import isFirstDayOfMonth from 'date-fns/is_first_day_of_month'
import Raven from 'raven-js'
import VueChartist from 'v-chartist'
import LoadingSpinner from 'vue-spinner/src/MoonLoader'
import { mapGetters } from 'vuex'
import { currencyCodeToSymbol } from '../helpers/currency-code-to-symbol'
import 'chartist-plugin-legend'
import ApiGateway from '@/services/api-gateway'
import { getErrorMessage } from '@/services/helpers'

export default {
  name: 'PropertyStatistics',

  components: {
    LoadingSpinner,
    VueChartist
  },

  data () {
    return {
      statistics: {},
      loading: true
    }
  },

  computed: {
    currencySymbol () { return currencyCodeToSymbol(this.activeCompany.currency.iso) },
    occupancy () { return this.statistics.occupancy },
    occupancyData () {
      const labels = this.occupancy.map(item => item.month)
      const data = this.occupancy.map(item => item.percentage)
      return {
        labels,
        series: [data]
      }
    },
    occupancyOptions () {
      return {
        fullWidth: true,
        height: 210,
        high: 100,
        chartPadding: { right: 40 },
        axisY: {
          onlyInteger: true,
          labelInterpolationFnc: this.percentLabel
        }
      }
    },
    sources () { return this.statistics.booking_sources },
    sourceData () {
      const labels = []
      const series = this.sources.map(item => item.booked)
      return {
        labels,
        series
      }
    },
    sourceOptions () {
      const legendNames = this.sources.map((item) => {
        const bookingSource = item.source
        return this.$store.getters.propertyItemBookingSourceLabel({ bookingSource })
      })
      return {
        labelOffset: 32,
        height: 200,
        plugins: [
          Chartist.plugins.legend({
            legendNames,
            position: 'bottom',
            clickable: false
          })
        ]
      }
    },
    rates () { return this.statistics.rates },
    rateData () {
      const data = this.rates.map(item => item.rate)
      const labels = this.rates.map(i => {
        if (isFirstDayOfMonth(i.date)) {
          return format(i.date, 'MMMM')
        }
        return null
      })
      return {
        labels,
        series: [data]
      }
    },
    rateOptions () {
      return {
        fullWidth: true,
        height: 210,
        lineSmooth: Chartist.Interpolation.step(),
        chartPadding: { right: 40 },
        showPoint: false,
        axisY: {
          low: 0,
          onlyInteger: true,
          labelInterpolationFnc: this.currencyLabel
        }
      }
    },
    revenue () { return this.statistics.booking_revenue },
    revenueData () {
      const labels = this.revenue.map(item => item.month)
      const data = this.revenue.map(item => item.amount)
      return {
        labels,
        series: [data]
      }
    },
    revenueOptions () {
      return {
        fullWidth: true,
        height: 210,
        chartPadding: { right: 40 },
        axisY: {
          onlyInteger: true,
          labelInterpolationFnc: this.currencyLabel
        }
      }
    },
    ...mapGetters([
      'activeCompanyId',
      'activeCompany',
      'activeLinkedOwnerId',
      'activeProperty',
      'lastBookedPropertyItem',
      'lastCancelledPropertyItem'
    ])
  },

  watch: {
    async lastBookedPropertyItem (item) {
      if (item.property_id === this.activeProperty.id) await this.getStatistics()
    },
    async lastCancelledPropertyItem (item) {
      if (item.property_id === this.activeProperty.id) await this.getStatistics()
    }
  },

  activated () {
    this.refreshCharts()
  },

  async created () {
    await this.getStatistics()
  },

  methods: {
    percentLabel (value) { return `${Math.round(value)}%` },
    currencyLabel (value) { return `${this.currencySymbol}${value}` },
    refreshCharts () {
      this.$children.forEach((item) => {
        if (Boolean(item.chartist)) {
          item.chartist.update()
        }
      })
    },
    async getStatistics () {
      this.loading = true
      try {
        const companyId = this.activeCompanyId
        const userId = this.activeLinkedOwnerId
        const propertyId = this.activeProperty.id
        const response = await ApiGateway.invokeApi({
          method: 'GET',
          pathTemplate: '/companies/{companyId}/owners/{userId}/properties/{propertyId}/statistics',
          params: {
            companyId,
            userId,
            propertyId
          }
        })
        this.statistics = response.data
      } catch (e) {
        Raven.captureException(e)
        this.$buefy.toast.open({
          message: `Error loading property statistics - ${getErrorMessage(e)}`,
          type: 'is-danger'
        })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="sass">
.property-statistics
  .ct-legend.ct-legend-inside
    position: relative
</style>
