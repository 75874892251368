/**
 * Returns the corresponding currency symbol for the provided currency code.
 * @param {string} currencyCode - ISO 4217 currency code.
 * @returns {string|undefined} - Currency symbol.
 */
export function currencyCodeToSymbol (currencyCode) {
  if (!Boolean(currencyCode)) throw new Error('Missing `currencyCode`')
  const code = currencyCode.toLowerCase()
  switch (code) {
    case 'usd':
    case 'cad':
    case 'aud':
      return '$'
    case 'gbp':
      return '£'
    case 'eur':
      return '€'
    case 'jpy':
      return '¥'
    default:
      return undefined
  }
}
