import Vue from 'vue'
import { imgUrl } from '../../helpers/img-url'
import * as types from '../mutation-types'
import ApiGateway from '@/services/api-gateway'

// Initial state
const state = {}

// Getters
const getters = {
  companyList (state) {
    return Object.keys(state).map((companyId) => {
      return state[companyId]
    })
  },
  activeCompanyId (state, getters, rootState) {
    if (!rootState.auth.user) return
    if (rootState.auth.user.attributes['custom:active_company_id']) return rootState.auth.user.attributes['custom:active_company_id']
    if (getters.companyList[0]) return getters.companyList[0].id
  },
  activeCompany (state, getters) {
    return state[getters.activeCompanyId]
  },
  activeCompanyLogoUrl (state, getters) {
    const activeCompany = getters.activeCompany
    if (!activeCompany || !activeCompany.logo) return
    const {
      company_id,
      path,
      file_name
    } = activeCompany.logo
    return imgUrl({
      companyId: company_id,
      path,
      fileName: file_name,
      queryParams: { h: 56 }
    })
  }
}

// Actions
const actions = {
  async getAllCompanies ({
    rootGetters,
    dispatch
  }) {
    if (rootGetters.linkedOwnerList.length === 0) {
      return
    }
    await Promise.all(rootGetters.linkedOwnerList.map(({
      company_id,
      id
    }) => {
      return dispatch('getCompany', {
        companyId: company_id,
        userId: id
      })
    }))
  },
  async getCompany ({ commit }, {
    companyId,
    userId
  }) {
    try {
      const response = await ApiGateway.invokeApi({
        method: 'GET',
        pathTemplate: '/owners/{userId}/companies/{companyId}',
        params: {
          companyId,
          userId
        }
      })
      const company = response.data
      commit(types.RECEIVE_COMPANY, { company })
    } catch (error) {
      console.error('Error fetching company:', error)
    }
  },
  async changeActiveCompany ({
    dispatch,
    rootState
  }, { companyId }) {
    try {
      // Get the current user from the amplify-auth store
      const user = rootState.auth.user

      if (!user) {
        console.error('No authenticated user found')
        return
      }

      // Update the custom:active_company_id attribute in Cognito
      await dispatch('auth/updateUserAttributes', { 'custom:active_company_id': companyId }, { root: true })

      // Refresh the user attributes to get the updated active company
      await dispatch('auth/getUserAttributes', null, { root: true })

      return true
    } catch (error) {
      console.error('Error changing active company:', error)
      throw error
    }
  }
}

// Mutations
const mutations = {
  [types.RECEIVE_COMPANY] (state, { company }) {
    Vue.set(state, company.id, company)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
