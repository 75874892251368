<template>
  <section class="section statement-list">
    <div class="container">
      <div
        v-if="ownerGroups && ownerGroups.length > 1"
        class="columns"
      >
        <div class="column is-four-fifths">
          <router-link
            to="/statements"
            class="view-link button is-info"
            tag="button"
          >
            Back
          </router-link>
        </div>
      </div>
      <div class="columns">
        <div class="column is-four-fifths">
          <h1
            v-if="currentOwnerGroup && !currentOwnerGroup.group_name && !loading"
            class="title is-4"
          >
            {{ currentOwnerGroup.first_name }} {{ currentOwnerGroup.last_name }}
          </h1>
          <h1
            v-if="currentOwnerGroup && currentOwnerGroup.group_name && !loading"
            class="title is-4"
          >
            {{ currentOwnerGroup.group_name }}
          </h1>
        </div>
        <div class="column">
          <button
            :class="{ 'is-loading': loading }"
            class="button is-info is-small"
            :disabled="!hasLatestYearEnd"
            @click="getLatestYearEndStatement"
          >
            <b-icon
              icon="file-pdf"
              size="is-small"
            />
            <span>Download Latest Year End Statement</span>
          </button>
        </div>
      </div>
      <b-message
        v-if="errorMessage"
        type="is-danger"
      >
        {{ errorMessage }}
      </b-message>
      <b-table
        :loading="loading"
        :data="statements"
        :empty="!loading && !statements.length && !errorMessage"
        default-sort="processed_to"
        default-sort-direction="desc"
      >
        <b-table-column
          v-slot="props"
          field="processed_to"
          label="Month"
          width="150"
          sortable
        >
          {{ formatProcessedMonth(props.row.processed_to) }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="completed_at"
          label="Completed"
          width="150"
          sortable
        >
          {{ formatCompletedAt(props.row.completed_at) }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="property_name"
          label="Properties"
        >
          <div
            v-for="item in findProperties(props.row)"
            :key="item"
          >
            {{ item }}
          </div>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="opening_balance"
          label="Opening Balance"
        >
          {{ formatCurrency(props.row.opening_balance) }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="closing_balance"
          label="Closing Balance"
        >
          {{ formatCurrency(props.row.closing_balance) }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="pdf_key"
          numeric
        >
          <statement-download-button
            :id="props.row.id"
            :pdf-key="props.row.pdf_key"
            :owner-id="props.row.user_id"
          />
        </b-table-column>

        <template #empty>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No statements found.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import Raven from 'raven-js'
import { mapGetters } from 'vuex'
import StatementDownloadButton from '@/components/StatementDownloadButton.vue'
import ApiGateway from '@/services/api-gateway'
import { getErrorMessage } from '@/services/helpers'

export default {
  name: 'StatementList',

  components: { StatementDownloadButton },

  props: {
    userId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      errorMessage: null,
      loading: true
    }
  },

  computed: mapGetters({
    statements: 'allStatements',
    hasLatestYearEnd: 'hasYearEndStatement',
    activeCompanyId: 'activeCompanyId',
    activeLinkedOwnerId: 'activeLinkedOwnerId',
    currentOwnerGroup: 'currentOwnerGroup',
    ownerGroups: 'ownerGroups'
  }),

  async created () {
    try {
      await this.$store.dispatch('getAllStatements', this.userId)
    } catch (e) {
      Raven.captureException(e)
      this.errorMessage = getErrorMessage(e)
    }
    this.loading = false
  },

  methods: {
    formatProcessedMonth (processedTo) { return format(parse(processedTo), 'MMMM YYYY') },
    formatCompletedAt (completedAt) { return format(parse(completedAt), 'MMM D, YYYY') },
    formatCurrency (amount) { return this.$store.getters.formattedCurrencyAmount(amount) },
    findProperties (statement) {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      const names = statement.properties_owned?.map(property => property.name) || []
      // For legacy statements without `properties_owned` attribute, fall back to owner_payments to find property name (if any)
      if (names.length === 0) {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        const properties = statement.line_items?.owner_payments?.properties || []
        for (const property of properties) {
          names.push(property.property_name)
        }
      }
      return names
    },
    async getLatestYearEndStatement () {
      this.pdfWindow = window.open('', '_blank') // Open blank window on `click` event to play nice with popup blockers.
      try {
        const response = await ApiGateway.invokeApi({
          method: 'GET',
          pathTemplate: '/companies/{companyId}/owners/{userId}/year-end-statement',
          params: {
            companyId: this.activeCompanyId,
            userId: this.activeLinkedOwnerId
          }
        })
        this.pdfWindow.location = response.data
        this.pdfWindow.focus()
      } catch (e) {
        Raven.captureException(e)
        this.errorMessage = getErrorMessage(e)
      }
    }
  }
}
</script>
