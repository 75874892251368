import tldjs from 'tldjs'
import { getEnv } from '../helpers/get-env'

const apiGwUrls = {
  local: 'http://owners.api.lmpm.localhost:3000', // Serverless Offline endpoint
  development: 'https://owners.api.lmpm.dev/v1',
  staging: 'https://owners.api.lmpm.io/v1',
  production: 'https://owners.api.lmpm.com/v1'
}

export default class Config {
  static get UserPoolId () {
    return process.env.VUE_APP_COGNITO_USER_POOL_ID
  }

  static get ClientId () {
    return process.env.VUE_APP_COGNITO_CLIENT_ID
  }

  static get ApiGwUrl () {
    if (getEnv() === 'local') return apiGwUrls.local
    if (getEnv() === 'development') return apiGwUrls.development
    if (getEnv() !== 'production') return apiGwUrls.development
    const tld = tldjs.getPublicSuffix(window.location.hostname)
    if (tld === 'com') return apiGwUrls.production
    return apiGwUrls.staging
  }

  /**
   * @returns {String} The current user's Cognito Identity ID.
   */
  static getCurrentUserCognitoUsername () {
    return window.localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.VUE_APP_COGNITO_CLIENT_ID}.LastAuthUser`)
  }
}
