<template>
  <section class="section view-property">
    <div class="container">
      <div
        v-if="property"
        class="is-block-mobile is-hidden-tablet"
      >
        <property-view-header :property="property" />
        <hr class="no-margin-bottom">
      </div>

      <div class="columns">
        <div class="column property-info">
          <loading-spinner
            :loading="loading"
            class="is-fullpage"
          />
          <b-message
            v-if="errorMessage"
            type="is-danger"
          >
            {{ errorMessage }}
          </b-message>
          <div v-if="property">
            <property-view-header
              :property="property"
              class="is-hidden-mobile"
            />
            <br class="is-hidden-mobile">
            <property-view-sub-nav :property-id="property.id" />
            <keep-alive>
              <router-view />
            </keep-alive>
          </div>
        </div>

        <div
          v-if="property"
          :class="{ 'is-hidden-mobile': isHiddenMobile }"
          class="column is-narrow"
        >
          <property-availability
            :company-id="activeCompanyId"
            :user-id="activeLinkedOwnerId"
            :property-id="propertyId"
            :fees="property.fees"
            :property-archived="property.archived"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Raven from 'raven-js'
import LoadingSpinner from 'vue-spinner/src/MoonLoader'
import { mapGetters } from 'vuex'
import PropertyAvailability from '@/components/PropertyAvailability.vue'
import PropertyViewHeader from '@/components/PropertyViewHeader.vue'
import PropertyViewSubNav from '@/components/PropertyViewSubNav.vue'
import { getErrorMessage } from '@/services/helpers'

export default {
  name: 'PropertyView',

  components: {
    LoadingSpinner,
    PropertyAvailability,
    PropertyViewHeader,
    PropertyViewSubNav
  },

  props: {
    propertyId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      property: null,
      errorMessage: null,
      loading: true,
      isHiddenMobile: true
    }
  },

  computed: {
    ...mapGetters([
      'activeCompanyId',
      'activeLinkedOwnerId',
      'activeProperty',
      'availabilityIsHiddenMobile'
    ])
  },

  watch: {
    availabilityIsHiddenMobile (hidden) {
      this.isHiddenMobile = hidden
    }
  },

  async created () {
    try {
      const propertyId = this.propertyId
      await this.$store.dispatch('getActiveProperty', { propertyId })
      this.property = this.activeProperty
    } catch (e) {
      Raven.captureException(e)
      this.errorMessage = getErrorMessage(e)
    }
    this.loading = false
  }
}
</script>

<style lang="sass" scoped>
.no-margin-bottom
  margin-bottom: 0

.property-info
  .title
    margin-bottom: .75rem

  .subheading .column
    padding-bottom: 0
</style>
