import { signOut } from '@aws-amplify/auth'
import {
  updatePassword,
  updateUserAttributes,
  fetchUserAttributes
} from 'aws-amplify/auth'
import * as types from '../mutation-types'

// Initial state
const state = {
  user: null,
  loading: false,
  error: null
}

// Getters
const getters = {
  isAuthenticated: state => !!state.user,
  user: state => state.user,
  loading: state => state.loading,
  error: state => state.error
}

// Actions
const actions = {
  async setUser ({ commit }, userData) {
    commit(types.SET_USER, userData)
  },

  async clearUser ({ commit }) {
    commit(types.CLEAR_USER)
  },

  async signOut ({ commit }) {
    commit(types.SET_LOADING, true)
    try {
      await signOut()
      commit(types.CLEAR_USER)
    } catch (error) {
      commit(types.SET_ERROR, error)
    } finally {
      commit(types.SET_LOADING, false)
    }
  },

  async changePassword (
    { commit },
    {
      oldPassword,
      newPassword
    }
  ) {
    commit(types.SET_LOADING, true)
    try {
      await updatePassword({
        oldPassword,
        newPassword
      })
      return true
    } catch (error) {
      commit(types.SET_ERROR, error)
      throw error
    } finally {
      commit(types.SET_LOADING, false)
    }
  },

  async updateUserAttributes (context, attributes) {
    context.commit(types.SET_LOADING, true)
    try {
      // Update the user attributes in Cognito
      await updateUserAttributes({ userAttributes: attributes })

      return true
    } catch (error) {
      context.commit(types.SET_ERROR, error)
      throw error
    } finally {
      context.commit(types.SET_LOADING, false)
    }
  },

  async getUserAttributes (context) {
    context.commit(types.SET_LOADING, true)
    try {
      // Fetch the latest user attributes from Cognito
      const userAttributes = await fetchUserAttributes()

      // If we have a user in the state, update their attributes
      if (context.state.user) {
        const updatedUser = {
          ...context.state.user,
          attributes: {
            ...context.state.user.attributes,
            ...userAttributes
          }
        }

        context.commit(types.SET_USER, updatedUser)
      }

      return userAttributes
    } catch (error) {
      context.commit(types.SET_ERROR, error)
      throw error
    } finally {
      context.commit(types.SET_LOADING, false)
    }
  }
}

// Mutations
const mutations = {
  [types.SET_USER] (state, userData) {
    state.user = userData
    state.error = null
  },

  [types.CLEAR_USER] (state) {
    state.user = null
  },

  [types.SET_LOADING] (state, isLoading) {
    state.loading = isLoading
  },

  [types.SET_ERROR] (state, error) {
    state.error = error
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
